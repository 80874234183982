import LoadingButton from "@mui/lab/LoadingButton";
import log from "loglevel";
import { useContext, useMemo, useState } from "react";
import { getIam, putIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import { Button } from "@redsift/design-system";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ALL_SIFTS } from "../libs/constants";

const getPendingSifts = async (
  product: string,
  ctx: any,
  setPendingSifts: Function
) => {
  const resp = await getIam(`/api/admin/pending-sifts/replay`, {
    guid: product,
  });
  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    log.debug("getSecrets::resp:", resp);
    setPendingSifts(resp);
  }
};

const replaySiftRequest = async (
  product: string,
  ctx: any,
  ids: { id: string }[]
) => {
  const resp = await putIam(
    `/api/admin/pending-sifts/replay`,
    ids,
    "application/json",
    { guid: product }
  );
  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Replay request sent", "success");
  }
};

const AdminPendingReplaySiftsExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [pendingSifts, setPendingSifts] = useState([]);
  const [isReplaying, setIsReplaying] = useState(false);
  const [product, setProduct] = useState("");

  const handleProductChange = (event: SelectChangeEvent) => {
    setPendingSifts([]);
    setProduct(event.target.value as string);
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "Pending Id",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "user.id",
        headerName: "User Id",
        flex: 1,
        valueGetter: (params: any) => params.row?.user?.id,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "user.email",
        headerName: "User Email",
        flex: 1,
        valueGetter: (params: any) => params.row?.user?.email,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "tracking.product",
        headerName: "Tracking Product",
        flex: 1,
        valueGetter: (params: any) => params.row?.tracking?.product,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "Replay",
        headerName: "Replay provisioning",
        flex: 1,
        valueParser: ({ value }: any) => value,
        renderCell: ({ row }: any) => (
          <Button
            isLoading={isReplaying}
            onClick={async () => {
              setIsReplaying(true);
              await replaySiftRequest(product, ctx, [{ id: row.id }]);
              await getPendingSifts(product, ctx, setPendingSifts);
              setIsReplaying(false);
            }}
            color="error"
          >
            Replay
          </Button>
        ),
      },
    ],
    [ctx, isReplaying, product]
  );
  return (
    <div>
      <form
        style={{
          display: "flex",
          flexFlow: "wrap",
          justifyContent: "space-between",
        }}
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          setPendingSifts([]);
          await getPendingSifts(product, ctx, setPendingSifts);
          setLoading(false);
        }}
      >
        <div style={{ display: "flex" }}>
          <FormControl style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}>
            <InputLabel id="product-selector-label">Product</InputLabel>
            <Select
              id="product-selector"
              value={product}
              label="Product"
              onChange={handleProductChange}
              required
            >
              {Object.keys(ALL_SIFTS)
                .sort((a: any, b: any) =>
                  ALL_SIFTS[a].name.toLowerCase() >
                  ALL_SIFTS[b].name.toLowerCase()
                    ? 1
                    : -1
                )
                .map((k, i) => (
                  <MenuItem key={i} value={k}>
                    {ALL_SIFTS[k].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={loading}
            variant="contained"
            style={{ margin: "5px 0px 0px 5px" }}
            type="submit"
          >
            Get
          </LoadingButton>
        </div>
      </form>
      <div
        style={{ display: "flex", marginTop: 30, justifyContent: "flex-end" }}
      >
        <Button
          isLoading={isReplaying}
          onClick={async () => {
            setIsReplaying(true);
            await replaySiftRequest(
              product,
              ctx,
              pendingSifts.map((s: any) => {
                return { id: s.id };
              })
            );
            await getPendingSifts(product, ctx, setPendingSifts);
            setIsReplaying(false);
          }}
          color="error"
        >
          Replay All
        </Button>
      </div>
      <SearchableTable
        tableId="pending-sifts-explorer"
        columns={columns}
        rows={pendingSifts}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </div>
  );
};

export default AdminPendingReplaySiftsExplorer;
