import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";

import { styled as muiStyled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import log from "loglevel";
import React, { Fragment, useContext, useState } from "react";
import { Icon, ButtonLink, Button } from "@redsift/design-system";
import { Tooltip, Popover } from "@redsift/popovers";
import styled from "styled-components";

import { mdiExitToApp } from "@redsift/icons";

import { PULSE_GUID, SIFTS, TOOLS } from "../libs/constants";
import { logout, profile } from "../libs/utils";
import AppContext from "./AppContext";
import ClosedBetaLogo from "../public/logo/closed-beta-icon.svg";
import ToolsLogo from "../public/logo/tools-icon.svg";
import ProfileLogo from "../public/logo/profile-icon.svg";
import AdminLogo from "../public/logo/admin-icon.svg";
import SiftSpaceLogo from "../public/logo/sift-space-icon.svg";
import CloseSideBarIcon from "../public/logo/close-sidebar-icon.svg";
import RadarIcon from "../public/logo/radar-icon.svg";
import OpenSideBarIcon from "../public/logo/open-sidebar-icon.svg";

import { MenuList } from "@mui/material";

const StyledDrawer = muiStyled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    justifyContent: "space-between",
    backgroundColor: "var(--redsift-color-neutral-darkgrey)",
    boxShadow: "4px 0px 0px 0px var(--redsift-color-neutral-black) inset",
    fontFamily: "Poppins",
    borderLeft: "0px",
  },
  "& .profile-section": {
    "@media (min-height: 1080px)": {
      marginBottom: "20px",
    },
  },
}));

const StyledToggleButtonGroup = muiStyled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    marginBottom: theme.spacing(0.5),
    border: 0,
    "&:not(:first-of-type)": {
      borderRadius: 1000,
    },
    "&:first-of-type": {
      borderRadius: 1000,
    },
    "&:hover > svg": {
      borderRadius: "100%",
      backgroundColor: "var(--redsift-color-neutral-black)",
    },
  },
}));

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledSvgIcon = muiStyled(SvgIcon)`
  width: 40px;
  height: 40px;
  padding: 5px;
`;

const StyledButtonLink = styled(ButtonLink)`
  margin: 4px auto;
  height: 57px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }
  &:hover > svg {
    border-radius: 50%;
    background-color: var(--redsift-color-neutral-black);
  }
`;

const FloatingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: -2px;
  padding: 4px 0 4px 4px !important;
  border: 1px solid var(--redsift-color-neutral-black) !important;
  background-color: var(--redsift-color-neutral-darkgrey) !important;
  box-shadow: "4px 0px 0px 0px var(--redsift-color-neutral-black) inset";
  border-radius: "var(--layout-corner-radius-corner-radius-md, 4px) 0px 0px var(--layout-corner-radius-corner-radius-md, 4px)";
  background: "var(--colours-primary-dark-grey, #333)";
`;

const StyledDivider = muiStyled(Divider)`
  border-color: var(--redsift-color-neutral-lightgrey);  
`;

const hasAccessToSift = (sifts: any, guid: string) => {
  if (!sifts) {
    return false;
  }
  return Boolean(sifts[guid]);
};

const SiftSwitcherDrawer = ({
  isLoading,
  selected,
  excludeApps = [],
  setSelected,
  hasTools = false,
  hasBetaAccess = false,
  isAdmin = false,
  isCustSupport = false,
  email,
  setPath,
  sifts,
  hasAccessToHardenize = false,
}: {
  isLoading: boolean;
  selected: any;
  excludeApps: string[];
  setSelected: Function;
  hasTools?: boolean;
  isAdmin?: boolean;
  isCustSupport?: boolean;
  hasBetaAccess?: boolean;
  email?: string;
  setPath: Function;
  sifts: any[];
  hasAccessToHardenize?: boolean;
}) => {
  const ctx = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const { setRightDrawerOpen, rightDrawerOpen } = ctx;
  const { setRadarParams } = ctx;

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    if (!isLoading) {
      const value = nextView || selected.guid;
      if (nextView === "profile") {
        mixpanel.track("Sift Drawer: Profile Clicked");
        profile();
      } else if (value) {
        mixpanel.track("Sift Drawer: Sift Clicked", {
          sift: SIFTS[value]?.name || TOOLS[value]?.name || value,
        });
        setSelected({ guid: value, instance: undefined }, false);
        setPath("");
      }
    }
  };

  const goToProfile = () => {
    mixpanel.track("Sift Drawer: Profile Clicked");
    profile();
  };

  const handleLogout = async () => {
    mixpanel.track("Sift Drawer: Logout Clicked");
    log.debug("SiftSwitcherDrawer::logout");
    await logout();
  };
  return (
    <>
      <Tooltip placement="left">
        <Tooltip.Trigger>
          <FloatingButton
            variant="unstyled"
            onClick={() => setRightDrawerOpen(true)}
            style={{ zIndex: 1200 }}
          >
            <StyledSvgIcon
              fontSize="large"
              style={{
                marginBottom: "-5px",
              }}
            >
              <OpenSideBarIcon></OpenSideBarIcon>
            </StyledSvgIcon>
          </FloatingButton>
        </Tooltip.Trigger>
        <Tooltip.Content>Open App Switcher</Tooltip.Content>
      </Tooltip>
      <StyledDrawer variant="persistent" anchor="right" open={rightDrawerOpen}>
        <div>
          <StyledToggleButtonGroup
            orientation="vertical"
            sx={{ padding: "5px", border: "0px", width: "100%" }}
            className="profile-section"
            onMouseLeave={() => {
              setIsOpen(false);
            }}
          >
            <ToggleButton value={"profile"} aria-label={"profile"}>
              <Tooltip>
                <Tooltip.Content>{email}</Tooltip.Content>
                <Tooltip.Trigger>
                  <StyledSvgIcon
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    fontSize="large"
                  >
                    <ProfileLogo></ProfileLogo>
                  </StyledSvgIcon>
                </Tooltip.Trigger>
              </Tooltip>
              <Popover isOpen={isOpen} onOpen={setIsOpen}>
                <Popover.Content
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "65px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MenuList
                    style={{
                      fontFamily: "Poppins",
                    }}
                    onMouseLeave={() => {
                      setIsOpen(false);
                    }}
                  >
                    <MenuItem style={{ cursor: "default" }} disabled>
                      <strong>User and Account</strong>
                    </MenuItem>
                    <MenuItem onClick={() => goToProfile()}>
                      <Icon
                        style={{ marginRight: "8px" }}
                        icon="M11.5816 20.426V22.395H3.56556C3.00289 22.395 2.52623 22.1997 2.13556 21.809C1.74489 21.4184 1.54956 20.9574 1.54956 20.426V4.39503C1.54956 3.8637 1.74489 3.4027 2.13556 3.01203C2.52623 2.62136 3.00289 2.42603 3.56556 2.42603H21.5656C22.1282 2.42603 22.6049 2.62136 22.9956 3.01203C23.3862 3.4027 23.5816 3.8637 23.5816 4.39503V12.504L23.3476 12.316C22.8162 11.7847 22.2226 11.519 21.5666 11.519V6.41003H3.56656V20.426H11.5826H11.5816ZM21.9876 13.723L23.2536 14.989C23.3476 15.1144 23.3946 15.255 23.3946 15.411C23.3946 15.567 23.3476 15.7077 23.2536 15.833L22.2696 16.817L20.1606 14.708L21.1446 13.724C21.3006 13.568 21.4412 13.49 21.5666 13.49C21.6919 13.49 21.8326 13.568 21.9886 13.724L21.9876 13.723ZM21.6596 17.332L15.6596 23.426H13.5506V21.317L19.6446 15.223L21.6596 17.332Z"
                      ></Icon>
                      Administration
                    </MenuItem>
                    <Divider></Divider>
                    <MenuItem onClick={async () => await handleLogout()}>
                      <Icon
                        icon={mdiExitToApp}
                        style={{ marginRight: "8px" }}
                      ></Icon>
                      Log Out
                    </MenuItem>
                  </MenuList>
                </Popover.Content>
              </Popover>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <StyledDivider />
          <StyledToggleButtonGroup
            orientation="vertical"
            value={selected.guid}
            exclusive
            onChange={handleChange}
            sx={{ padding: "5px", border: "0px" }}
          >
            {Object.keys(SIFTS).map((k: string, i: number) => {
              const s = SIFTS[k];
              if (excludeApps.includes(s.guid)) {
                return null;
              }
              if (s?.onlyShowIfHaveAccess) {
                if (!hasAccessToSift(sifts, s.guid)) {
                  return null;
                }
              }
              // START: Special cases around access to Hardenize (and the associated sifts)
              if (s.guid === "hardenize" && !hasAccessToHardenize) {
                return null;
              }
              if (s.guid === "asm" && hasAccessToHardenize && !isCustSupport) {
                return null;
              }
              if (
                s.guid ===
                  "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1" &&
                hasAccessToHardenize &&
                !hasAccessToSift(sifts, s.guid) &&
                !isCustSupport
              ) {
                return null;
              }
              // END: Special cases around access to Hardenize (and the associated sifts)
              return (
                <ToggleButton key={i} value={s.guid} aria-label={s.name}>
                  <div
                    className="toggle-group-selected-indicator"
                    style={{
                      position: "absolute",
                      left: "-5px",
                      width: "4px",
                      height: "38px",
                      borderRadius: "0px 4px 4px 0px",
                      backgroundColor: "var(--redsift-color-neutral-white)",
                      visibility:
                        selected.guid === s.guid ? "visible" : "hidden",
                    }}
                  ></div>
                  <Tooltip placement="left" delay={100}>
                    <Tooltip.Trigger>
                      <StyledSvgIcon fontSize="large">
                        <s.Logo />
                      </StyledSvgIcon>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{s.name}</Tooltip.Content>
                  </Tooltip>
                </ToggleButton>
              );
            })}
          </StyledToggleButtonGroup>
          {hasAccessToSift(sifts, PULSE_GUID) && (
            <Fragment>
              <StyledDivider />
              <StyledToggleButtonGroup
                orientation="vertical"
                exclusive
                sx={{ padding: "5px", border: "0px" }}
                onClick={() => {
                  const { radarParams } = ctx;
                  setRadarParams({
                    ...radarParams,
                    radarOpen: true,
                    showRadar: true,
                  });
                }}
              >
                <ToggleButton
                  value={"toggle drawer"}
                  aria-label={"toggle drawer"}
                >
                  <Tooltip placement="left" delay={100}>
                    <Tooltip.Trigger>
                      <StyledSvgIcon fontSize="large">
                        <RadarIcon />
                      </StyledSvgIcon>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{"Radar"}</Tooltip.Content>
                  </Tooltip>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Fragment>
          )}
          {(hasTools || hasBetaAccess) && (
            <Fragment>
              <StyledDivider />
              <StyledToggleButtonGroup
                orientation="vertical"
                value={selected.guid}
                exclusive
                onChange={handleChange}
                sx={{ padding: "5px", border: "0px" }}
              >
                {hasTools && (
                  <ToggleButton value={"tools"} aria-label={"tools"}>
                    <Tooltip placement="left" delay={100}>
                      <Tooltip.Trigger>
                        <StyledSvgIcon fontSize="large">
                          <ToolsLogo />
                        </StyledSvgIcon>
                      </Tooltip.Trigger>
                      <Tooltip.Content>Tools</Tooltip.Content>
                    </Tooltip>
                  </ToggleButton>
                )}
                {hasBetaAccess && (
                  <ToggleButton
                    value={"closed-beta"}
                    aria-label={"closed-beta"}
                  >
                    <Tooltip placement="left" delay={100}>
                      <Tooltip.Trigger>
                        <StyledSvgIcon fontSize="large">
                          <ClosedBetaLogo />
                        </StyledSvgIcon>
                      </Tooltip.Trigger>
                      <Tooltip.Content>Red Sift Beta</Tooltip.Content>
                    </Tooltip>
                  </ToggleButton>
                )}
              </StyledToggleButtonGroup>
            </Fragment>
          )}
          {(isAdmin || isCustSupport) && (
            <Fragment>
              <StyledDivider />
              <StyledToggleButtonGroup
                orientation="vertical"
                value={selected.guid}
                exclusive
                onChange={handleChange}
                sx={{ padding: "5px", border: "0px" }}
              >
                <ToggleButton value={"admin"} aria-label={"admin"}>
                  <Tooltip placement="left" delay={100}>
                    <Tooltip.Trigger>
                      <StyledSvgIcon fontSize="large">
                        <AdminLogo />
                      </StyledSvgIcon>
                    </Tooltip.Trigger>
                    <Tooltip.Content>Red Sift Admin</Tooltip.Content>
                  </Tooltip>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Fragment>
          )}
          <ButtonGroup>
            <StyledDivider />
            <StyledButtonLink
              aria-label={"Sift Space"}
              target="_blank"
              rel="noreferrer"
              variant="unstyled"
              href={process.env.NEXT_PUBLIC_COMMUNITY_URL}
            >
              <Tooltip placement="left" delay={100}>
                <Tooltip.Trigger>
                  <StyledSvgIcon fontSize="large">
                    <SiftSpaceLogo />
                  </StyledSvgIcon>
                </Tooltip.Trigger>
                <Tooltip.Content>Sift Space</Tooltip.Content>
              </Tooltip>
            </StyledButtonLink>
          </ButtonGroup>
        </div>
        <ButtonGroup>
          <StyledToggleButtonGroup
            orientation="vertical"
            exclusive
            sx={{ padding: "5px", border: "0px" }}
            onClick={() => setRightDrawerOpen(false)}
          >
            <ToggleButton value={"toggle drawer"} aria-label={"toggle drawer"}>
              <Tooltip placement="left" delay={100}>
                <Tooltip.Trigger>
                  <StyledSvgIcon fontSize="large">
                    <CloseSideBarIcon />
                  </StyledSvgIcon>
                </Tooltip.Trigger>
                <Tooltip.Content>Hide App Switcher</Tooltip.Content>
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </ButtonGroup>
      </StyledDrawer>
    </>
  );
};

export default SiftSwitcherDrawer;
