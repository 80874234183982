import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import log from "loglevel";
import { useContext, useMemo, useState } from "react";
import { ALL_SIFTS } from "../libs/constants";
import { getIam, postIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";

const postSecrets = async (
  product: string,
  secrets: any[] = [],
  add: any = {},
  remove: string[] = [],
  ctx: any
) => {
  const secr: any = { ...add };
  secrets.forEach((s: any) => {
    if (!secr[s.key]) {
      secr[s.key] = s.value;
    }
  });
  remove.forEach((k) => {
    delete secr[k];
  });

  const resp: any = await postIam(
    "/api/admin/secrets",
    secr,
    "application/json",
    {
      guid: product,
      owner: ALL_SIFTS[product].sift.owner,
      name: ALL_SIFTS[product].sift.name,
    }
  );

  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert("Added secret", "success");
  }
};

const getSecrets = async (
  product: string,
  ctx: any,
  setSecrets: Function,
  setCanSet: Function
) => {
  const resp = await getIam("/api/admin/secrets", {
    guid: product,
    owner: ALL_SIFTS[product].sift.owner,
    name: ALL_SIFTS[product].sift.name,
  });
  if (resp.error) {
    ctx.setAlert(resp.error, "error");
  } else {
    if (Object.keys(resp).length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    log.debug("getSecrets::resp:", resp);
    const secr = Object.keys(resp).map((k) => ({
      id: k,
      key: k,
      value: resp[k],
    }));
    setSecrets(secr);
    setCanSet(true);
  }
};

const AdminSecretsExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [canSet, setCanSet] = useState(false);
  const [showSecrets, setShowSecrets] = useState(false);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [product, setProduct] = useState("");
  const [secrets, setSecrets] = useState<any>([]);

  const handleProductChange = (event: SelectChangeEvent) => {
    setSecrets([]);
    setCanSet(false);
    setProduct(event.target.value as string);
  };

  const columns = useMemo(
    () => [
      {
        field: "key",
        headerName: "Key",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "value",
        headerName: "Value",
        flex: 1,
        renderCell: ({ value }: any) => (
          <TooltipCell value={value} show={showSecrets} />
        ),
      },
      {
        field: "delete",
        headerName: "",
        renderCell: ({ row }: any) => (
          <LoadingButton
            loading={loading}
            onClick={async () => {
              setLoading(true);
              log.debug("AdminSecretsExplorer::delete", row);
              await postSecrets(product, secrets, {}, [row.id], ctx);
              await getSecrets(product, ctx, setSecrets, setCanSet);
              setLoading(false);
            }}
            variant="contained"
            color="error"
          >
            Delete
          </LoadingButton>
        ),
      },
    ],
    [loading, product, secrets, showSecrets, ctx]
  );

  return (
    <div>
      <form
        style={{
          display: "flex",
          flexFlow: "wrap",
          justifyContent: "space-between",
        }}
        onSubmit={async (ev) => {
          ev.preventDefault();
          setLoading(true);
          setSecrets([]);
          await getSecrets(product, ctx, setSecrets, setCanSet);
          setLoading(false);
        }}
      >
        <div style={{ display: "flex" }}>
          <FormControl style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}>
            <InputLabel id="product-selector-label">Product</InputLabel>
            <Select
              labelId="product-selector-label"
              id="product-selector"
              value={product}
              label="Product"
              onChange={handleProductChange}
              required
            >
              {Object.keys(ALL_SIFTS)
                .sort((a: any, b: any) =>
                  ALL_SIFTS[a].name.toLowerCase() >
                  ALL_SIFTS[b].name.toLowerCase()
                    ? 1
                    : -1
                )
                .map((k, i) => (
                  <MenuItem key={i} value={k}>
                    {ALL_SIFTS[k].name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={loading}
            variant="contained"
            style={{ margin: "5px 0px 0px 5px" }}
            type="submit"
          >
            Get
          </LoadingButton>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            variant="outlined"
            onClick={() => setShowSecrets(!showSecrets)}
          >
            {showSecrets ? "Hide" : "Show"}
          </Button>
        </div>
      </form>
      {canSet && (
        <form
          style={{
            display: "flex",
            flexFlow: "wrap",
            marginTop: 25,
          }}
          onSubmit={async (ev) => {
            ev.preventDefault();
            setLoading(true);
            log.debug("AdminSecretsExplorer::setting secret");
            await postSecrets(
              product,
              secrets,
              { [key]: value },
              undefined,
              ctx
            );
            log.debug("AdminSecretsExplorer::setting secret::getting secrets");
            await getSecrets(product, ctx, setSecrets, setCanSet);
            setKey("");
            setValue("");
            setLoading(false);
          }}
        >
          <TextField
            style={{ margin: "5px 0px 0px 5px", flex: 1 }}
            label="Key"
            value={key}
            onChange={(ev) => setKey(ev.target.value)}
            required
          />
          <TextField
            style={{ margin: "5px 0px 0px 5px", flex: 1 }}
            label="Value"
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            required
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            style={{ margin: "5px 0px 0px 5px" }}
            type="submit"
          >
            Set
          </LoadingButton>
        </form>
      )}
      <SearchableTable
        tableId="secrets-explorer"
        columns={columns}
        rows={secrets}
        style={{ marginTop: 20 }}
      />
    </div>
  );
};

export default AdminSecretsExplorer;
