import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components";

import { ALL_SIFTS, DEFAULTS } from "../libs/constants";
import background from "../public/files/background.png";
import { Icon } from "@redsift/design-system";

const MainContainer = styled.main`
  width: calc(100vw - 67px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${background.src});
`;

const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  margin: 10px auto;
  padding: 0 60px;
  max-width: 1200px;
`;

const WaitingRoom = ({ product }: { product: string }) => {
  const {
    Hero = DEFAULTS.Hero,
    tagline = DEFAULTS.tagline,
    icon,
  } = ALL_SIFTS[product];
  const splitScreen = useMediaQuery("(min-width:1100px)");

  return (
    <MainContainer>
      <StyledPaper
        sx={{
          borderRadius: "0px",
          border: "1px solid var(--redsift-color-neutral-darkgrey, #333)",
          boxShadow: "15px 15px 0px var(--redsift-color-neutral-lightgrey)",
        }}
      >
        <div
          style={{
            width: splitScreen ? "50%" : "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <Icon
              icon={icon}
              size={{ width: 200, height: 64, fontSize: 64, lineHeight: 64 }}
            ></Icon>
          </div>
          <Typography variant="h3">
            <strong>{tagline}</strong>
          </Typography>
          <div style={{ display: "flex", margin: "10px 0px" }}>
            Your account is being provisioned. We will notify you when it is
            ready.
          </div>
        </div>
        {splitScreen && (
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <Hero width={460} height={460} />
          </div>
        )}
      </StyledPaper>
    </MainContainer>
  );
};

export default WaitingRoom;
