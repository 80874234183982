import log from "loglevel";
import { useEffect, useMemo, useState } from "react";
import useCheckAuth from "./useCheckAuth";
import useCheckCompatibility from "./useCheckCompatibility";
import useCheckConfig from "./useCheckConfig";
import useCheckOnline from "./useCheckOnline";
import useGetSifts from "./useGetSifts";
import useGetUser from "./useGetUser";
import useRefreshSiftJwt from "./useRefreshSiftJwt";

const useAppStartup = () => {
  const [state, setState] = useState<any>({
    isLoading: true,
    error: undefined,
    data: undefined,
  });

  // NOTE: stub for offline development
  // const checkOnline = useMemo(() => ({ isLoading: false, error: undefined }), []);
  const checkOnline = useCheckOnline();
  const online = useMemo(
    () => !checkOnline.isLoading && !checkOnline.error,
    [checkOnline]
  );

  // NOTE: stub for offline development
  // const checkCompatibility = useMemo(() => ({ isLoading: false, error: undefined }), []);
  const checkCompatibility = useCheckCompatibility(online);

  const checkAuth = useCheckAuth(online);
  const authenticated = useMemo(
    () => !checkAuth.isLoading && !checkAuth.isIdle && !checkAuth.error,
    [checkAuth]
  );

  const config = useCheckConfig(online);

  const user = useGetUser(online && authenticated);
  const sifts = useGetSifts(online && authenticated);

  const siftsJwt = useRefreshSiftJwt(online && authenticated);

  useEffect(() => {
    if (!checkOnline.isLoading && checkOnline.error) {
      log.error("useAppStartup::offline", checkOnline.error);
      setState({ isLoading: false, error: "offline", data: undefined });
      return;
    } else if (!checkCompatibility.isLoading && checkCompatibility.error) {
      log.error("useAppStartup::compatibility", checkCompatibility.error);
      setState({
        isLoading: false,
        error: checkCompatibility.error,
        data: undefined,
      });
      return;
    } else if (!checkAuth.isLoading && !checkAuth.isIdle && checkAuth.error) {
      log.info(
        "useAppStartup::authentication::redirecting to IAM...",
        checkAuth.error
      );
      // Do nothing as the user is being redirected
      return;
    } else if (!user.isLoading && !user.isIdle && user.error) {
      log.error("useAppStartup::user::could not load info", user.error);
      setState({ isLoading: false, error: user.error, data: undefined });
      return;
    } else if (!sifts.isLoading && !sifts.isIdle && sifts.error) {
      log.error("useAppStartup::sifts::could not load info", sifts.error);
      setState({ isLoading: false, error: sifts.error, data: undefined });
      return;
    } else if (!siftsJwt.isLoading && !siftsJwt.isIdle && siftsJwt.error) {
      log.error("useAppStartup::siftsJwt::could not load jwts", siftsJwt.error);
      setState({ isLoading: false, error: siftsJwt.error, data: undefined });
      return;
    } else if (
      checkOnline.isLoading ||
      checkCompatibility.isLoading ||
      checkAuth.isLoading ||
      config.isLoading ||
      user.isLoading ||
      sifts.isLoading ||
      siftsJwt.isLoading
    ) {
      log.info("useAppStartUp::loading");
      return;
    } else {
      log.info("useAppStartUp::done");
      // All data loaded
      setState({
        isLoading: false,
        error: undefined,
        data: {
          user: user.data,
          sifts: sifts.data,
          jwt: checkAuth.data,
          siftsJwt: siftsJwt.data,
          config: config.data,
        },
      });
    }
    // NOTE: setting the object as the dependency creates an infinite render loop hence the long list
  }, [
    checkOnline.error,
    checkOnline.isLoading,
    checkCompatibility.error,
    checkCompatibility.isLoading,
    checkAuth.data,
    checkAuth.error,
    checkAuth.isLoading,
    checkAuth.isIdle,
    user.error,
    user.isLoading,
    user.isIdle,
    user.data,
    sifts.error,
    sifts.isLoading,
    sifts.isIdle,
    sifts.data,
    siftsJwt.error,
    siftsJwt.isLoading,
    siftsJwt.isIdle,
    siftsJwt.data,
    config.isLoading,
    config.data,
  ]);

  return state;
};

export default useAppStartup;
