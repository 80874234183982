import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import log from "loglevel";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import CONFIG from "../config/default.json";
import {
  ALL_SIFTS,
  DEFAULTS,
  WAITING_ROOM_SIFT_GUIDS,
} from "../libs/constants";
import { installSift } from "../libs/sift-utils";
import { invalidateAllQueries } from "../libs/utils";
import AppContext from "./AppContext";
import background from "../public/files/background.png";
import { mdiArrowRight } from "@redsift/icons";
import { Icon, Button } from "@redsift/design-system";

const MainContainer = styled.main`
  width: calc(100vw - 67px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${background.src});
`;

const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  margin: 10px auto;
  padding: 0 60px;
  max-width: 1200px;
`;

const GetProduct = ({ product }: { product: string }) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const ctx = useContext(AppContext);
  const queryClient = useQueryClient();
  const available: string[] = CONFIG.AVAILABLE_SIFTS;

  const {
    Hero = DEFAULTS.Hero,
    tagline = DEFAULTS.tagline,
    learnmore,
    name,
    guid,
    icon,
    isSelfServe,
  } = ALL_SIFTS[product];
  const splitScreen = useMediaQuery("(min-width:1100px)");

  return (
    <MainContainer>
      <StyledPaper
        sx={{
          borderRadius: "0px",
          border: "1px solid var(--redsift-color-neutral-darkgrey, #333)",
          boxShadow: "15px 15px 0px var(--redsift-color-neutral-lightgrey)",
        }}
      >
        <div
          style={{
            width: splitScreen ? "50%" : "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <Icon
              icon={icon}
              size={{ width: 200, height: 64, fontSize: 64, lineHeight: 64 }}
            ></Icon>
          </div>
          <Typography variant="h3">
            <strong>{tagline}</strong>
          </Typography>
          <div style={{ display: "flex", margin: "10px 0px" }}>
            {isSelfServe && !!available.find((s: string) => s === product) && (
              <Button
                isLoading={loading}
                style={{ marginRight: 10 }}
                rightIcon={mdiArrowRight}
                aria-label={`Start ${name}`}
                onClick={async () => {
                  setLoading(true);
                  const { id, found, error } = await installSift(
                    guid,
                    {},
                    {
                      checkUserDomain: "true",
                      ...(WAITING_ROOM_SIFT_GUIDS.includes(guid) && {
                        waitingRoom: "true",
                      }),
                    }
                  );
                  if (error) {
                    ctx.setAlert(error, "error");
                  } else if (found) {
                    setOpenDialog(true);
                  } else {
                    log.debug("GetProduct::installed", id);
                    await invalidateAllQueries(queryClient);
                  }
                  setLoading(false);
                }}
              >
                Start {name}
              </Button>
            )}
            {learnmore && (
              <Button
                rightIcon={mdiArrowRight}
                variant={isSelfServe ? "secondary" : "primary"}
                onClick={() => window.open(learnmore, "_blank", "noreferrer")}
              >
                LEARN MORE
              </Button>
            )}
          </div>
        </div>
        {splitScreen && (
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <Hero width={460} height={460} />
          </div>
        )}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <Card>
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to create a new ${name} account?`}
            </DialogTitle>

            <DialogContent>
              {`It looks like someone else from your organization has already created one.`}
              <form
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  setLoading(true);
                  setOpenDialog(true);
                  const { id, error } = await installSift(guid, {},  {
                    ...(WAITING_ROOM_SIFT_GUIDS.includes(guid) && {
                      waitingRoom: "true",
                    }),
                });
                  if (error) {
                    ctx.setAlert(error, "error");
                  } else {
                    log.debug("InstanceSelector::installed", id);
                    await invalidateAllQueries(queryClient);
                  }
                  setLoading(false);
                  setOpenDialog(false);
                }}
              >
                <Button
                  isLoading={loading}
                  type="submit"
                  style={{ margin: "30px 0px 0px 5px" }}
                >
                  Create anyway
                </Button>
              </form>
            </DialogContent>
          </Card>
        </Dialog>
      </StyledPaper>
    </MainContainer>
  );
};

export default GetProduct;
